/* =============================================================================================
    ANIMATIONS
============================================================================================= */

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in-slide-left {
  from {
    transform: translateX(8rem);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translateY(-2%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* =============================================================================================
    PAGE SPLASH
============================================================================================= */

.page-splash {
  display: flex;
  width: 100%;
  z-index: 1000;
  height: 100%;
  opacity: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.57);
  position: fixed;
  top: 0;
  left: 0;
  cursor: wait;
}

.spinner:not(.is-upgraded).is-active:after {
  content: '';
  color: var(--accent);
  font-size: 5.625rem;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  animation: loader 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes loader {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
      -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* =============================================================================================
    GRAY LOADER
============================================================================================= */

.gray-loader {
  display: block;
  position: relative;
  opacity: 0.4;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background-color: var(--light-green);
    mix-blend-mode: color;
    cursor: not-allowed;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    color: #444;
    overflow: hidden;
    width: 1rem;
    height: 1rem;
    z-index: 1;
    border-radius: 50%;
    animation: loader 1.7s infinite ease, round2 1.7s infinite ease;
  }
}

@keyframes round2 {
  0% {
    -webkit-transform: rotate(0deg) scale(3);
    transform: rotate(0deg) scale(3);
  }

  100% {
    -webkit-transform: rotate(360deg) scale(3);
    transform: rotate(360deg) scale(3);
  }
}
