/* =============================================================================================
	COLLAPSE (Accordion) (TO DO: DEPRECATE IN FAVOUR OF MATERIAL)
============================================================================================= */

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
}

.accordion {
  &__btn {
    padding: 0;
    background-color: transparent;
    color: #fff;
    width: 100%;
    text-align: left;
  }

  .card:nth-child(even) {
    .card-header {
      background-color: var(--primary);
      border-bottom: 0;
    }
  }
}
