/* =============================================================================================
	PRIMENG PAGINATOR (Only edit paginator styles at bottom)
============================================================================================= */

.p-paginator {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0 0 0;
  text-align: center;

  &-first,
  &-last,
  &-prev,
  &-next {
    background: url(/assets/img/right-arrow-gray.svg) no-repeat center !important;
    background-size: 0.9rem !important;
    width: 0.8rem !important;

    &.p-disabled {
      opacity: 0.5;
    }
  }

  &-first,
  &-last {
    border-right: 2px solid #666666 !important;
  }

  &-first,
  &-prev {
    transform: scale(-1);
  }

  .fa {
    display: none;
  }

  .p-paginator-top {
    border-bottom: 0 none;
  }

  .p-paginator-bottom {
    border-top: 0 none;
  }

  .p-paginator-left-content {
    float: left;
  }

  .p-paginator-right-content {
    float: right;
  }

  .p-paginator-page,
  .p-paginator-pages,
  .p-paginator-next,
  .p-paginator-last,
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-current {
    display: inline-block;
    width: 1.5em;
    height: 1rem !important;
    line-height: 1.5em;
    zoom: 1;
    margin: 0 0.1rem;
    text-decoration: none;
    vertical-align: middle;
    text-align: center;
    position: relative;
  }

  .p-paginator-pages {
    width: auto;
    line-height: 1;
  }

  .p-paginator-icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    margin-left: -0.5em;
  }

  .p-paginator-page,
  .p-paginator-next,
  .p-paginator-last,
  .p-paginator-first,
  .p-paginator-prev {
    cursor: pointer;
  }

  .p-paginator-current,
  .p-paginator-rpp-options {
    margin-left: 1em;
    margin-right: 1em;
    background-image: none;
  }

  .p-paginator-jtp-select option,
  .p-paginator-rpp-options option {
    background-image: none;
    border: 0 none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  button {
    background-color: transparent;
    border: none;

    &.p-disabled {
      outline: 0 none;
    }
  }

  .p-dropdown {
    min-width: 4em;
    margin-left: 0.375em;
  }

  .p-paginator-page {
    color: rgba($color: #000000, $alpha: 0.54);
    font-size: 0.9rem;
    line-height: 1.1;

    &.p-highlight {
      color: var(--accent) !important;
      font-weight: 700;
    }
  }
}

@media (min-width: 768px) {
  .p-paginator {
    .p-paginator-page,
    .p-paginator-pages,
    .p-paginator-next,
    .p-paginator-last,
    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-current {
      margin: 0 0.3rem;
    }
  }
}
