.blocked {
  position: absolute;
  background-color: white;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}
