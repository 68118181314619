@use '@angular/material' as mat;
// @use '@angular/material/core/theming';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Raleway:wght@700&display=swap');

/* =============================================================================================
	TYPOGRAPHY
============================================================================================= */

/* ------------------------------------------------------------------------ Headings */

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-family: var(--font-stack);
  font-weight: 300;
  line-height: 1.2;
  color: var(--ramp-black);
  margin: 0 0 1rem 0;
}

h1 {
  font-family: 'Raleway' !important;
  font-size: 2rem;
}

h2 {
  font-weight: 500;
  font-size: 1.25rem;
}

h3 {
  font-size: 1.1rem;
}

@media (min-width: 992px) {
  h1 {
    font-size: 2.25rem;
    margin-bottom: 1.7rem;
  }

  h3 {
    font-size: 1.1rem;
  }
}

/* ------------------------------------------------------------------------ Other Base Text Styles */

b,
strong {
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.text-primary {
  color: var(--primary) !important;
}
.text-secondary {
  color: var(--accent) !important;
}
.text-accent {
  color: var(--accent) !important;
}

.text-dark {
  color: #444 !important;
}

.fs-18 {
  font-size: 1.125rem;
}

.required {
  color: var(--error);
}

/* ------------------------------------------------------------------------ Material Typography */

$my-typography: mat.m2-define-typography-config(
  $font-family: 'Montserrat',
  $headline-1: mat.m2-define-typography-level(36px, 30.6px, 700, 'Raleway'),
  $headline-2: mat.m2-define-typography-level(24px, 20.4px, 500, 'Montserrat'),
  $button: mat.m2-define-typography-level(17px, 17px, 800, 'Montserrat', 'normal')
);
