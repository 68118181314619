/*ALL REM CONVERSIONS BASED ON 16PX*/

@import './assets/scss/variables';

//Bootstrap Utilities (cherry-picked)
@import './assets/scss/bootstrap-utilities';

//Custom Material Theme
@import './assets/scss/theme';

@import './assets/scss/base';
@import './assets/scss/mixins';
@import './assets/scss/typography';
@import './assets/scss/utilities';
@import './assets/scss/images';
@import './assets/scss/animation';
@import './assets/scss/alert';
@import './assets/scss/links';
@import './assets/scss/divider';
@import './assets/scss/lists';
@import './assets/scss/collapse';
@import './assets/scss/navigation';
@import './assets/scss/top-bar';
@import './assets/scss/section';
@import './assets/scss/card';
@import './assets/scss/tooltip';

//PrimeNG
@import './assets/scss/primeng-table';
@import './assets/scss/pagination';

@import './assets/scss/tables';
@import './assets/scss/forms';
@import './assets/scss/dock';
@import './assets/scss/tour';
@import './assets/scss/q-grid';

// UI Blocker
@import './assets/scss/ui-blocker';

// Scrollbars
@import './assets/scss/scrollbar';

//overrides
@import './assets/scss/overrides';
