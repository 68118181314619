/* =============================================================================================
  Material Snackbar
============================================================================================= */
@import '_animation';

.mat-snackbar {
  max-width: 45vw !important;

  simple-snack-bar {
    padding-left: 0.5rem;
  }
  // TODO: Get spinner centered
  &--working {
    simple-snack-bar::before {
      content: '';
      color: white;
      width: 1em;
      height: 1em;
      translate: 0.5rem 1rem;
      border-radius: 50%;
      position: relative;
      transform: translateZ(0);
      animation: loader 1.7s infinite ease, round 1.7s infinite ease;
    }
  }

  &--done {
    simple-snack-bar::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background-repeat: no-repeat;
      background-image: url(../img/checkmark-circle-icon-white.svg);
    }
  }

  &--warn {
    simple-snack-bar::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background-repeat: no-repeat;
      background-image: url(../img/warning-icon-white.svg);
    }
  }

  &--error {
    simple-snack-bar::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background-repeat: no-repeat;
      background-image: url(../img/error-icon-white.svg);
    }
  }
}

/* =============================================================================================
	ALERT (TO DO: DEPRECATE IN FAVOUR OF MATERIAL)
============================================================================================= */

.alert {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
  margin: 0.25rem 0;
  border: none;

  &-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}

/* =============================================================================================
	Q-ALERT (Styled like Material Banner)
============================================================================================= */

.q-alert {
  position: sticky;
  top: 0;
  padding: 1rem;
  word-break: break-word;
  background-color: #fff;
  display: flex;
  align-items: center;
  align-self: flex-start;
  border-bottom: 1px solid var(--light-gray);
  width: 100%;
  opacity: 0;
  animation: fade-in-down 0.5s ease-in-out 0.5s forwards;

  &--error {
    background: #fff url(/assets/img/error-icon.svg) no-repeat 1.5rem center;
    padding-left: 4rem !important;
  }

  &__heading {
    font-size: 0.9375rem;
    margin-bottom: 0;
  }

  &__heading + &__heading {
    margin-top: 0.25rem;
  }
}

@media (min-width: 768px) {
  .q-alert {
    padding: 1.5rem;

    &__heading {
      font-size: 1rem;
    }
  }

  .section .q-alert {
    margin: -1.5rem -1.5rem 0 -1.5rem;
    width: calc(100% + 3rem);
  }

  .table-container .q-alert {
    margin: 0;
    width: 100%;
  }
}
