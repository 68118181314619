/* =============================================================================================
	TOP BAR GLOBAL STYLES (since we are using multiple components for this area, its easier to do part of it globally.
============================================================================================= */

/* =============================================================================================
  MOBILE HEADER
============================================================================================= */

.mobile-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: var(--ramp-light-blue);
  border-bottom: 1px solid var(--light-gray);
  padding: 0.5rem 0;
  min-height: 3.05rem;
  padding: 0 0.9375rem;
}

@media (min-width: 1200px) {
  .mobile-header {
    display: none;
  }
}

/* =============================================================================================
  TOP BAR TOGGLE
============================================================================================= */

.top-bar-toggle {
  width: 1.5rem;
  height: 1.5rem;

  &__icon {
    stroke: var(--primary);
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    transition: transform 0.25s ease-in-out;
    transform-origin: center;

    &:hover {
      transform: rotate(90deg);
    }
  }
}

/* =============================================================================================
TOP BAR
============================================================================================= */

.top-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--ramp-light-blue);

  app-context,
  traffic-filters > form {
    @include grid;
    grid-template-columns: 1fr;
    align-items: center;
  }
}

/*this is used in multiple components TODO: move*/
app-predefined-date {
  position: relative;
  width: 100%;
  display: block;
}

@media (max-width: 1199px) {
  .top-bar {
    display: block;
    height: 0;
    opacity: 0;
    transition: all ease-in-out 0.5s, opacity ease-in-out 1s;
    visibility: hidden;

    &--shown {
      border-bottom: 1px solid var(--light-gray);
      height: 100%;
      max-height: 35rem;
      opacity: 1;
      padding: 1rem 1.5rem;
      transition: all ease-in-out 0.5s, opacity ease-in-out 1s;
      visibility: visible;
    }
  }
}

@media (min-width: 1200px) {
  .top-bar {
    position: sticky;
    top: 0;
    z-index: 102;
    padding: 0.5rem 1.5rem 0.5rem 4.5rem;
    min-height: 4.375rem;
    @include grid;
    grid-template-columns: 1fr;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: 1px solid var(--light-gray);
  }
}
