/* =============================================================================================
	THEMES
============================================================================================= */

@use '@angular/material' as mat;
// @use '@angular/material/core/theming';

@import './_typography';

@include mat.core();

/* =============================================================================================
	LIGHT THEME
============================================================================================= */

$my-theme: mat.m2-define-light-theme((
  color: (
    primary: $my-theme-primary,
    accent: $my-theme-accent,
    warn: $my-theme-warn
  ),
  typography: $my-typography,
  density: 0
));

@include mat.all-component-themes($my-theme);



/* =============================================================================================
	DARK THEME
============================================================================================= */

/*when this class is added to the body, the dark theme will be active*/
body.dark-theme {
  //Dark Theme Variables
  $dark-primary: mat.m2-define-palette($my-dark-primary);
  $dark-accent: mat.m2-define-palette($my-accent);
  $dark-theme: mat.m2-define-dark-theme($dark-primary, $dark-accent, $my-theme-warn);

  @include mat.all-component-themes($dark-theme);

  //Overrides default theme variables
  --primary: #b3b3b3;
  --dark-grey: #3c3c46;
  --border-color: rgba(255, 255, 255, 0.12);
  --light-gray: rgba(255, 255, 255, 0.7);

  .theme-switcher__btn {
    color: #444;
  }

  //Login
  .login-container {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-label {
      color: #a3a3a3 !important;
    }
  }

  //Sidenav
  .logo {
    background-image: url(/assets/img/logo-reverse.svg);
  }

  .sidenav {
    .mat-mdc-nav-list {
      border-top-color: var(--border-color);
    }
  }

  .mat-drawer-side {
    border-right-color: transparent;
  }

  .user-area {
    background-color: transparent;

    &__user-icon {
      stroke: var(--light-gray);
    }

    &__btn-icon {
      stroke: var(--dark-grey);
    }

    &__span {
      color: var(--light-gray);
    }
  }

  //Topbar
  .top-bar {
    background-color: transparent;
    border-bottom-color: var(--border-color);
  }

  //Forms
  label {
    color: var(--light-gray);
  }

  .form-control,
  .custom-select {
    background-color: #303030;
    color: #fff;
    padding-left: 0.75rem;
  }

  .p-autocomplete-panel {
    background: var(--dark-grey);
  }

  //Filter
  .filter-global {
    background-color: #353539;
  }

  .filter-btn {
    background-color: transparent;
  }

  //Bottom Panel
  .bottom-panel {
    background-color: #303030;
    &__title {
      background-color: var(--dark-grey);
      color: #fff;
    }

    &__nav {
      background-color: var(--dark-grey);
    }
  }

  //Misc
  .filter-box,
  .daytoggle,
  .date-container {
    background-color: var(--dark-grey);
  }

  //Tables
  .p-datatable {
    table {
      background-color: transparent !important;
    }

    .p-datatable-thead > tr > th {
      color: #fff;
      background-color: #353539;
    }

    thead th,
    tbody td,
    tfoot td,
    tfoot th {
      border: none !important;
    }

    .p-column-title {
      color: #fff;
    }

    .p-datatable-tbody tr:nth-child(odd) {
      background-color: var(--dark-grey);
    }

    .p-datatable-tbody tr td {
      color: #fff;
    }

    .p-datatable-tbody tr:hover td,
    .selected {
      color: #444 !important;
      background-color: #fff;
    }

    .selected > td {
      color: #444 !important;
    }

    .p-sortable-column.p-state-highlight {
      background-color: #fff;
      color: #444;
    }
  }

  .table-container::-webkit-scrollbar-track {
    background-color: #353539;
  }

  //Alerts
  .q-alert {
    --primary: #126f6e;
  }
}
