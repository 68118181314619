.auth-content {
  margin: 0;
  background-image: url("apps/partner/frontend/ui/src/assets/img/ramp-partner-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #FFF;
}

#sign-in-widget {
  --text-color: #f1f1f1;
  --light-blue: #5CBBAC;
  --light-blue-hover: #6CC2B4;
  --error-color: #ED5D5C;
  --dark-text-color: #020508;
  color: #F1F1F1;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

#sign-in-widget .beacon-container a {
  color: var(--light-blue);
}

#sign-in-widget .beacon-container p {
  color: var(--text-color);
  font-size: 1rem;
  font-weight: bold;
}

#sign-in-widget .auth-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#sign-in-widget .auth-content-inner::before {
  content: "";
  background: url("apps/partner/frontend/ui/src/assets/img/ramp-logo-rev.svg") no-repeat center;
  height: 56px;
  align-self: center;
  width: 40%;
  min-width: 40%;
}

#sign-in-widget .primary-auth,
#sign-in-widget .mfa-verify,
#sign-in-widget .forgot-password {
  border-left: 1px solid #F1F1F1;
  padding: 2rem;
  width: 60%;
}

#sign-in-widget .auth-content-inner {
  display: flex;
  padding: 2rem;
  background: #091B29;
  margin: auto;
  align-self: center;
  min-width: 55rem;
  transition: all 0.25s;
}

#sign-in-widget .okta-form-title {
  display: none;
}

#sign-in-widget .o-form-fieldset {
  padding: 1rem 0;
}

#sign-in-widget .o-form-input-container {
  padding-top: 1rem;
}

#sign-in-widget .o-form-input-name-username input,
#sign-in-widget .o-form-input-name-password input,
#sign-in-widget .o-form-input-name-answer input {
  margin-top: .5rem;
  border: none;
  border-bottom: 1px solid var(--light-blue);
  background: transparent;
  padding: .5rem;
  width: 100%;
  outline: none;
}



/*This label is an override of shared library styling. Will need to move over all shared lib styles and control
from within RAMP Partner application separate from shared library*/
#sign-in-widget label {
  color: var(--text-color) !important;
  font-size: 1rem !important;
}

#sign-in-widget .o-form-input > span > input {
  color: var(--text-color) !important;
  font-size: 1rem !important;
}

#sign-in-widget #okta-signin-password {
  color: var(--text-color);
  font-size: 1rem;
}

#sign-in-widget #okta-signin-username {
  color: var(--text-color);
  font-size: 1rem;
}

#sign-in-widget .okta-form-infobox-error.infobox.infobox-error {
  color: var(--text-color);
  font-size: 1rem;
  font-weight: bold;  
}

#sign-in-widget .okta-form-input-error,
#sign-in-widget o-form-input-error,
#sign-in-widget o-form-explain {
  color: var(--text-color);
  font-weight: bold;
}

#sign-in-widget .button,
#sign-in-widget .button-primary {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  width: 100%;
  background-color: var(--light-blue);
  border: none;
  padding: 1rem;
  font-weight: bold;
  font-size: 1.125rem !important;
  cursor: pointer;
  display: block;
  text-align: center;
  color: #091B29;
  transition: all .25s;
  min-height: 4rem;
}

#sign-in-widget .button:hover,
#sign-in-widget .button-primary:hover {
  background-color: var(--light-blue-hover);
}

#sign-in-widget .button a:hover,
#sign-in-widget .button a:active,
#sign-in-widget button a:focus,
#sign-in-widget a.button:hover,
#sign-in-widget a.button:active,
#sign-in-widget a.button:focus{
  color: #020508;
}

#sign-in-widget .auth-footer {
  display: block;
  margin-top: 1rem;
}

#sign-in-widget .help-links li,
#sign-in-widget .help-links li a,
#sign-in-widget .auth-footer a {
  color: #ED5D5C;
}

#sign-in-widget .auth-content::after {
  content: "By";
  background: url('apps/partner/frontend/ui/src/assets/img/system1-logo.svg') no-repeat 1.5rem;
  width: 10rem;
  margin-left: 1rem;
  font-style: italic;
  color: #f1f1f1;
  line-height: initial;
}

#sign-in-widget .factors-dropdown-wrap {
  background: #091B29;
  padding: 1rem;
}

#sign-in-widget .dropdown {
  position: relative;
  display: inline-block;
}

#sign-in-widget .options {
  background-color: #091B29;
  z-index: 1;
}

#okta-dropdown-options {
  position: absolute;
  width: 100%;
  top: 2.575rem;
  box-shadow: 0px 4px 5px #020508;
}

#sign-in-widget ul.okta-dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

#sign-in-widget ul.okta-dropdown-list li {
  padding: .6rem;
  border-bottom: 1px solid #22323E;
  cursor: pointer;
}

#sign-in-widget ul.okta-dropdown-list li:hover {
  background-color: #020508;

}

#sign-in-widget ul.okta-dropdown-list li:hover a,
#sign-in-widget span.off-screen {
  color: #ED5D5C;
}

#sign-in-widget span.off-screen::after {
  content: "";
  background: url(/assets/img/down-arrow.svg) no-repeat bottom;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-left: 0.5rem;
  transition-property: transform;
  transition-duration: .25s;
}

#sign-in-widget [aria-expanded="true"] span.off-screen::after {
  transform: rotate(180deg);
  transition-property: transform;
  transition-duration: .25s;
}

#sign-in-widget li.dropdown-list-title.okta-dropdown-option.option.option-disabled {
  display: none;
}

#sign-in-widget .number-challenge-view > p:first-of-type {
  display: none;
}

@media (max-width: 900px) {
  #sign-in-widget .auth-content-inner {
    display: grid;
    min-width: 100vw;
    padding: 1rem;
  }

    #sign-in-widget .auth-content-inner::before {
      width: 100%;
    }

  #sign-in-widget .primary-auth {
    width: 100%;
    border-left: none !important;
    padding: 1rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
  -webkit-box-shadow: 0 0 0px 0px #091B29 inset;
}

/*OKTA forgot password styles*/

#sign-in-widget .password-expired {
  border-left: 1px solid #F1F1F1;
  padding: 2rem;
  width: 40rem;
  text-align: justify;
}

#sign-in-widget .password-expired input[type=password],
#sign-in-widget .password-expired input[type=password]:focus,
#sign-in-widget .password-expired input[type=password]:active {
  background: #091B29;
  border: none;
  border-bottom: 1px solid var(--light-blue);
  outline: none;
  width: 100%;
}
