/* =============================================================================================
	DOCK (SHARED STYLES) (for component specific styles, see that component...)
============================================================================================= */

.dock {
  position: relative;
  @include grid;
  grid-template-rows: auto 1fr;
  grid-auto-rows: max-content;
  flex: 1;
  background-color: var(--light-green);
  border-top: 1px solid #d2dfe0;
  transition: all 0.25s ease-in-out;
  padding: 0 1.5rem 1.5rem 1.5rem;
  grid-template-areas:
    'grid-title'
    'grid-nav'
    'grid-content';
  height: 100%;

  &__btn-group {
    display: flex;
    position: absolute;
    right: 0.75rem;
    top: 0.3rem;
    z-index: 3;
  }

  &__close-btn,
  &__max-btn,
  &__min-btn {
    padding: 0 !important;
    width: 1.4rem !important;
    height: 1.4rem !important;
    background-color: #fff !important;
    margin-left: 0.5rem !important;
    -webkit-appearance: none;

    svg {
      width: 0.75rem;
      height: 0.75rem;
      stroke: var(--accent);
      stroke-width: 3;
      stroke-linecap: butt;
    }
  }

  &__max-btn svg {
    transform: rotate(-90deg);
  }

  &__min-btn svg {
    transform: rotate(90deg);
  }

  &__title {
    font-weight: 500;
    text-align: center;
    padding: 0.25rem 0.5rem;
    background-color: #fff;
    color: #6b8282;
  }

  &__loader {
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }

  .options-bar {
    background-color: #fff;
  }

  &-content-wrapper {
    display: grid;
    grid-template-columns: max-content auto;
  }

  .dock__title {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    height: 2.1rem;
    grid-area: grid-title;
  }
}

@media (min-width: 1600px) {
  .dock {
    overflow: auto;
    padding: 0 1.5rem 1.5rem 0;
    grid-template-columns: 10rem 1fr;
    grid-template-areas:
      'grid-title grid-title'
      'grid-nav grid-content';
    height: 100%;
    z-index: 2;

    &__no-menu {
      grid-template-columns: 1fr;
    }

    &__loader {
      grid-area: grid-content;
    }

    &__title {
      grid-area: grid-title;
      font-size: 1rem;
      margin-right: -1.5rem;
    }

    &__btn-group {
      right: 1.5rem;
      top: 0.25rem;
    }

    /*for docks that only have a single column*/
    &--single-col {
      grid-template-columns: 1fr;
      grid-template-areas:
        'grid-title'
        'grid-content';
      padding-left: 1.5rem;

      .dock__title {
        margin-left: -1.5rem;
        height: 2.1rem;
      }
    }
  }

  .mat-mdc-nav-list--vertical {
    grid-row: 2;
  }
}

/* ------------------------------------------------------------------------  Dock Nav */

.dock__nav {
  background-color: #fff;
  padding-top: 0 !important;
  @include grid(0);
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;

  .mat-mdc-list-item {
    border-bottom: 1px solid var(--light-green);

    &:last-of-type {
      border-bottom: 0;
    }

    &:nth-child(odd) {
      border-right: 1px solid var(--light-green);
    }

    &--active {
      color: var(--primary) !important;
      font-weight: 500;

      .mdc-list-item {
        &__primary-text {
          color: var(--primary) !important;
        }
      }
    }
  }

  .mdc-list-item {
    &__content {
      padding: 0.5rem 0 !important;
    }
  }

  .mat-mdc-nav-list__text {
    font-size: 0.75rem;
    white-space: break-spaces;
  }
}

@media (min-width: 768px) {
  .dock__nav {
    grid-template-columns: none;
    grid-auto-flow: column;
    justify-content: start;

    .mat-mdc-list-item {
      border-bottom: 0;
      border-right: 1px solid var(--light-green);

      &:last-of-type {
        border-right: 0;
      }
    }
  }
}

@media (min-width: 1600px) {
  .dock__nav {
    grid-area: grid-nav;
    grid-auto-flow: row;
    align-content: start;
    border-radius: 0 5px 5px 0;
    padding-top: 0.75rem !important;

    &.mat-mdc-nav-list {
      overflow-y: auto;
    }

    .mat-mdc-list-item,
    .mat-list-item:nth-child(odd) {
      border: none;
    }

    .mat-mdc-list-item__content {
      text-align: left;
      padding: 0.5rem 1rem !important;
    }

    .mat-mdc-nav-list__text {
      font-size: 0.9rem;
    }
  }
}
