::ng-deep .mdc-button {
}

.mdc-button {
  padding: 24px;
  transition: all 0.25s;
}

.mat-mdc-button {
  border-radius: 50px !important;
}

app-predefined-date .label {
  color: var(--ramp-blue);
}

mat-form-field .label,
mat-form-field mat-label {
  color: var(--ramp-blue);
}

.cdk-overlay-pane > div {
  padding: 0 !important;
}

.cdk-overlay-pane > div > cdk-virtual-scroll-viewport {
  overflow-x: hidden !important;
}

mat-card-title {
  font-weight: 500;
  font-size: 1.25rem;
}

.mat-mdc-form-field-required-marker {
  color: var(--ramp-red);
}

.alert-danger {
  background-color: transparent !important;
  border: none !important;
  margin-top: -1rem !important;
  color: var(--ramp-red) !important;
}

.mdc-tooltip__surface {
  font-size: 0.7rem !important;
  background-color: var(--ramp-black) !important;
  color: white !important;
  padding: 0.5rem !important;
}

mat-form-field {
  width: 100%;
}
.mdc-menu-surface.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-visible {
  overflow: hidden !important;
  padding: 0 !important;
  max-height: fit-content !important;
}
.mat-mdc-text-field-wrapper {
  background-color: var(--ramp-light-gray-2);
}

.mdc-line-ripple::before {
  border-bottom-width: 2px !important;
}

.mat-mdc-card-outlined {
  border: none !important;
  box-shadow: 1px 1px 3px var(--ramp-light-gray);
  background-color: var(--ramp-light-gray-2);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: var(--ramp-blue);
  --mdc-filled-button-label-text-color: #fff;
}

.mat-mdc-unelevated-button.mat-secondary {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}

.mat-mdc-simple-snack-bar {
  display: flex;
  align-items: center;
}

.mat-mdc-menu-panel {
  overflow: visible !important;
  border-radius: 10px !important;


  .mat-mdc-menu-content {
    padding: 10px !important;
  }
}

.mat-mdc-menu-item-text > span{
  display: flex !important;
}
