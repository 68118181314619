/* =============================================================================================
	MAT NAV LIST
============================================================================================= */

.mat-mdc-list .mat-mdc-list-item,
.mat-list .mat-list-option,
.mat-mdc-nav-list .mat-list-item,
.mat-mdc-nav-list .mat-list-option,
.mat-selection-list .mat-list-item,
.mat-selection-list .mat-list-option {
  display: flex !important;
  height: auto !important;
}

.mat-list-item__content {
  height: auto !important;
  width: 100%;
}

.mat-mdc-list-item {
  height: auto !important;
}
