/*
  This is a global style file for qGrid.
  This will be used to provide a common experience between our internal apps.
*/

.q-grid-container {
  width: 100%;

  .options-bar {
    @include grid;
    background-color: var(--light-green);
    border: 1px solid rgba(136, 149, 149, 0.3);
    border-bottom: 0;
    /* autoprefixer: ignore next*/
    grid-auto-flow: column;
    grid-template-columns: minmax(13rem, 25%) auto;
    justify-content: flex-end;
    min-height: 3.25rem;
    padding: 0.5rem 0.8rem;

    &__page-size {
      display: flex;
      align-items: center;
      /* autoprefixer: ignore next*/
      grid-column-end: -1;

      &-label {
        color: rgba(0, 0, 0, 0.54);
        font-size: 12px;
        white-space: nowrap;
        margin-right: 0.5rem;
      }

      &-field {
        font-size: 12px;
        width: 3.5rem;
      }
      /*
  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
      .mat-form-field-wrapper {
        padding: 1rem 0;
        width: 3.5rem;
      }
      /*
  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
      .mat-form-field-infix {
        border-top: 0;
      }
    }

    &__pagination {
      align-items: center;
      display: flex;
    }

    @media screen and (min-width: 48rem) {
      grid-template-columns: minmax(15rem, 25%) auto;
    }
  }

  .grid-table-container {
    display: block;
    width: 100%;
    min-height: 10rem;
    max-height: 100%;
    max-width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
