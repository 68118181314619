/* width & height*/
::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.75rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--ramp-light-gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--ramp-light-blue);
}
