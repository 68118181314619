/* =============================================================================================
	BASE STYLES
============================================================================================= */

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/assets/fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/fonts/montserrat-v25-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/assets/fonts/montserrat-v25-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('/assets/fonts/montserrat-v25-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/assets/fonts/montserrat-v25-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/assets/fonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/fonts/montserrat-v25-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/assets/fonts/montserrat-v25-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('/assets/fonts/montserrat-v25-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/assets/fonts/montserrat-v25-latin-700.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}

* {
  font-family: 'Montserrat';
}

html {
  font-family: 'Montserrat';
  font-size: 100%;
  height: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  position: relative;
  font: normal 100%/1.6 var(--font-stack);
  color: #444444;
  min-height: 100%;
  background-color: #f9fafb;
}

.main {
  display: flex;
  flex-direction: column;
  overflow: auto;
  transition: all 0.25s ease-in-out;
  height: 100vh;
  padding-top: 3rem;

  /*when the dock is open, shrink any expandable sections*/
  &--panel-opened .section--expandable {
    flex: 0 0 50%;
  }

  /*when the dock is bottomSectionMaximizedState*/
  &--panel-bottomSectionMaximizedState .section--expandable {
    padding: 0;
    flex: 0 !important;
    grid-template-rows: 0 !important;
    grid-gap: 0 !important;
    opacity: 0;

    /*hide everything in that section, if not removed from dom*/
    > * {
      padding: 0;
      height: 0;
    }
  }
}

@media (min-width: 1200px) {
  .main {
    padding-top: 0;
  }
}
