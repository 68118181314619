/* =============================================================================================
	PRIMENG TABLE STYLES (Cherry picked, don't edit this file, modify in tables.scss)
============================================================================================= */

/* =============================================================================================
	UI DATATABLE
============================================================================================= */

.p-datatable {
  position: relative;
}

.p-datatable table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-datatable .p-datatable-header,
.p-datatable .p-datatable-caption,
.p-datatable .p-datatable-footer {
  text-align: center;
  padding: 0.5em 0.75em;
  box-sizing: border-box;
}

.p-datatable .p-datatable-caption,
.p-datatable .p-datatable-header {
  border-bottom: 0 none;
}

.p-datatable .p-datatable-footer {
  border-top: 0 none;
}

.p-datatable thead th,
.p-datatable tfoot td {
  text-align: center;
}

.p-datatable thead tr {
  border-width: 0;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td,
.p-datatable .p-datatable-data > tr > td {
  border-color: inherit;
  box-sizing: border-box;
  padding: 0.25em 0.5em;
  border-width: 1px;
  border-style: solid;
}

.p-datatable.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable.p-datatable-resizable .p-datatable-data > tr > td {
  overflow: hidden;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td {
  font-weight: normal;
}

.p-datatable tbody {
  outline: 0;
}

.p-datatable .p-sortable-column {
  cursor: pointer;
}

.p-datatable .p-sortable-column-icon {
  display: inline-block;
  margin-left: 0.125em;
}

.p-datatable tr.p-highlight {
  cursor: pointer;
}

.p-datatable-scrollable-body {
  overflow: auto;
  overflow-anchor: none;
  min-height: 0;
}

.p-datatable-scrollable-header,
.p-datatable-scrollable-footer {
  overflow: hidden;
}

.p-datatable-scrollable .p-datatable-scrollable-header,
.p-datatable-scrollable .p-datatable-scrollable-footer {
  position: relative;
  border: 0 none;
}

.p-datatable-scrollable .p-datatable-scrollable-header td {
  font-weight: normal;
}

.p-datatable-scrollable-body .p-datatable-data,
.p-datatable-scrollable-body .p-datatable-data > tr:first-child {
  border-top-color: transparent;
}

.p-datatable .p-datatable-data tr.p-hover,
.p-datatable .p-datatable-data tr.p-highlight {
  border-color: inherit;
  font-weight: inherit;
  cursor: pointer;
}

.p-datatable .p-datatable-data tr.p-rowgroup-header td a,
.p-datatable .p-datatable-data tr.p-rowgroup-header td span.p-rowgroup-header-name {
  display: inline-block;
  vertical-align: middle;
}

.p-datatable-scrollable-theadclone {
  height: 0;
}

.p-datatable-scrollable-theadclone tr {
  height: 0;
}

.p-datatable-scrollable-theadclone th.p-default {
  height: 0;
  border-bottom-width: 0;
  border-top-width: 0;
  padding-top: 0;
  padding-bottom: 0;
  outline: 0 none;
}

.p-datatable-scrollable-theadclone th span.p-column-title {
  display: block;
  height: 0;
}

.p-datatable-rtl {
  direction: rtl;
}

.p-datatable-rtl.p-datatable thead th,
.p-datatable-rtl.p-datatable tfoot td {
  text-align: right;
}

.p-row-toggler {
  cursor: pointer;
}

.p-datatable .p-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: 0.5em;
  height: 100%;
  padding: 0;
  cursor: col-resize;
  border: 1px solid transparent;
}

.p-datatable .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
}

.p-datatable-resizable {
  padding-bottom: 1px;
  overflow: auto;
}

.p-datatable-resizable thead th,
.p-datatable-resizable tbody td,
.p-datatable-resizable tfoot td {
  white-space: nowrap;
}

.p-datatable-resizable th.p-resizable-column {
  background-clip: padding-box;
  position: relative;
}

.p-datatable-reflow .p-datatable-data td .p-column-title {
  display: none;
}

.p-datatable .p-column-filter {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.25em;
}

.p-datatable .p-editable-column input {
  width: 100%;
  outline: 0;
}

.p-datatable .p-datatable-data > tr > td.p-editable-column {
  padding: 0.5em;
}

.p-datatable .p-editable-column > .p-cell-editor {
  display: none;
}

.p-datatable .p-datatable-data > tr > td.p-editable-column.p-cell-editing {
  padding: 1px;
}

.p-datatable .p-editable-column.p-cell-editing > .p-cell-editor {
  display: block;
}

.p-datatable .p-editable-column.p-cell-editing > .p-cell-data {
  display: none;
}

.p-datatable-stacked thead th,
.p-datatable-stacked tfoot td {
  display: none !important;
}

.p-datatable.p-datatable-stacked .p-datatable-data > tr > td {
  text-align: left;
  display: block;
  border: 0 none;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  clear: left;
}

.p-datatable.p-datatable-stacked .p-datatable-data.p-component-content {
  border: 0 none;
}

.p-datatable-stacked .p-datatable-data tr.p-component-content {
  border-left: 0 none;
  border-right: 0 none;
}

.p-datatable-stacked .p-datatable-data td .p-column-title {
  padding: 0.4em;
  min-width: 30%;
  display: inline-block;
  margin: -0.4em 1em -0.4em -0.4em;
  font-weight: bold;
}

.p-datatable .p-selection-column .p-chkbox,
.p-datatable .p-selection-column .p-radiobutton {
  margin: 0;
  display: block;
}

.p-datatable .p-selection-column .p-chkbox-box,
.p-datatable .p-selection-column .p-radiobutton-box {
  display: block;
  box-sizing: border-box;
  margin: 0;
}

.p-datatable-scrollable-wrapper {
  position: relative;
}

.p-datatable-frozen-view .p-datatable-scrollable-body {
  overflow: hidden;
}

.p-datatable-unfrozen-view {
  position: absolute;
  top: 0;
}

.p-datatable .p-datatable-load-status {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.p-datatable .p-datatable-virtual-table {
  position: absolute;
  top: 0;
  left: 0;
}

.p-datatable .p-datatable-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: 1;
}

.p-datatable .p-datatable-loading-content {
  position: absolute;
  left: 50%;
  top: 25%;
  z-index: 2;
}

@media (max-width: 35em) {
  .p-datatable-reflow thead th,
  .p-datatable-reflow tfoot td {
    display: none !important;
  }

  .p-datatable-reflow .p-datatable-data > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100% !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
  }

  .p-datatable-reflow .p-datatable-data.p-component-content {
    border: 0 none;
  }

  .p-datatable-reflow .p-datatable-data tr.p-component-content {
    border-left: 0 none;
    border-right: 0 none;
  }

  .p-datatable-reflow .p-datatable-data td .p-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }

  .p-datatable-reflow.p-datatable-scrollable .p-datatable-scrollable-body colgroup {
    display: block;
  }
}

/* =============================================================================================
	UI TABLE
============================================================================================= */

.p-datatable {
  position: relative;
}

.p-datatable table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-tfoot > tr > td {
  padding: 0.25em 0.5em;
}

.p-datatable .p-sortable-column {
  cursor: pointer;
}

.p-datatable .p-datatable-sort-icon {
  vertical-align: middle;
}

.p-datatable-auto-layout > .p-datatable-wrapper {
  overflow-x: auto;
}

.p-datatable-auto-layout > .p-datatable-wrapper > table {
  table-layout: auto;
}

.p-datatable-caption,
.p-datatable-summary {
  padding: 0.25em 0.5em;
  text-align: center;
  font-weight: bold;
}

.p-datatable-caption {
  border-bottom: 0 none;
}

.p-datatable-summary {
  border-top: 0 none;
}

.p-datatable-scrollable-wrapper {
  position: relative;
}

.p-datatable-scrollable-header,
.p-datatable-scrollable-footer {
  overflow: hidden;
  border: 0 none;
}

.p-datatable-scrollable-body {
  overflow: auto;
  position: relative;
}

.p-datatable-scrollable-body > table > .p-datatable-tbody > tr:first-child > td {
  border-top: 0 none;
}

.p-datatable-virtual-table {
  position: absolute;
}

.p-datatable-frozen-view .p-datatable-scrollable-body {
  overflow: hidden;
}

.p-datatable-frozen-view
  > .p-datatable-scrollable-body
  > table
  > .p-datatable-tbody
  > tr
  > td:last-child {
  border-right: 0 none;
}

.p-datatable-unfrozen-view {
  position: absolute;
  top: 0;
}

.p-datatable-resizable > .p-datatable-wrapper {
  overflow-x: auto;
}

.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable-resizable .p-datatable-tbody > tr > td {
  overflow: hidden;
}

.p-datatable-resizable .p-resizable-column {
  background-clip: padding-box;
  position: relative;
}

.p-datatable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
  display: none;
}

.p-datatable .p-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: 0.5em;
  height: 100%;
  padding: 0;
  cursor: col-resize;
  border: 1px solid transparent;
}

.p-datatable .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  background-color: blue;
  z-index: 10;
  display: none;
}

.p-datatable .p-datatable-tbody > tr > td.p-editing-cell input {
  padding: 0;
  border: 0 none;
}

.p-datatable .p-datatable-tbody > tr > td.p-editing-cell p-celleditor > * {
  width: 100%;
}

.p-datatable-reorder-indicator-up,
.p-datatable-reorder-indicator-down {
  position: absolute;
  display: none;
}

.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .p-datatable-responsive .p-datatable-thead > tr > th,
  .p-datatable-responsive .p-datatable-tfoot > tr > td {
    display: none !important;
  }

  .p-datatable-responsive .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100% !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
  }

  .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }
}

/*-------------------------------------------------------PrimeNG multiselect--------------------------------------------------*/

.p-multiselect {
  display: inline-block;
  position: relative;
  width: auto;
  cursor: pointer;
}

.p-multiselect .p-multiselect-trigger {
  border-right: none;
  border-top: none;
  border-bottom: none;
  cursor: pointer;
  width: 1.5em;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 0.25em;
}

.p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  position: absolute;
}

.p-multiselect .p-multiselect-label-container {
  overflow: hidden;
}

.p-multiselect .p-multiselect-label {
  display: block;
  padding: 0.25em 2em 0.25em 0.25em;
  width: auto;
  border: none;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-multiselect.p-disabled .p-multiselect-trigger,
.p-multiselect.p-disabled .p-multiselect-label {
  cursor: auto;
}

.p-multiselect-panel {
  padding: 0.2em;
  position: absolute;
  min-width: 12em;
}

.p-multiselect .p-multiselect-panel {
  background-color: #ffffff !important;
  min-width: 100%;
}

.p-multiselect-panel .p-multiselect-items-wrapper {
  overflow: auto;
  position: relative;
  padding: 0.2em 0;
}

.p-multiselect-panel .p-multiselect-list {
  border: 0 none;
}

.p-multiselect-panel .p-multiselect-item {
  border: 0 none;
  cursor: pointer;
  font-weight: normal;
  margin: 1px 0;
  padding: 0.125em 0.25em;
  text-align: left;
  white-space: nowrap;
  display: block;
  position: relative;
}

.p-multiselect-panel .p-multiselect-item .p-chkbox {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-panel .p-multiselect-item label {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-header {
  margin-bottom: 0.3em;
  padding: 0.25em;
  position: relative;
  text-align: left;
  min-height: 2em;
}

.p-multiselect-header .p-chkbox {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.p-multiselect-header .p-multiselect-filter-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 65%;
}

.p-multiselect-header.p-multiselect-header-no-toggleall .p-multiselect-filter-container {
  width: 85%;
}

.p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  position: absolute;
  top: 0.25em;
  left: 0.125em;
}

.p-multiselect-header .p-inputtext {
  padding: 0.125em 0.125em 0.125em 1.25em;
  width: 100%;
}

.p-multiselect-header .p-multiselect-close {
  position: absolute;
  right: 0.375em;
  top: 0.375em;
  display: block;
  border: 0 none;
}

.p-multiselect-header a.p-multiselect-all,
.p-multiselect-header a.p-multiselect-none {
  float: left;
  margin-right: 10px;
  display: block;
}

.p-multiselect-header .p-multiselect-close.p-hover {
  padding: 0;
}

.p-multiselect-footer {
  padding: 0.25em;
}

.p-fluid .p-multiselect {
  width: 100%;
  box-sizing: border-box;
}

/*custom multiselect*/
.p-multiselect {
  width: 100%;
  border: 1px solid #cecdcd;
  border-radius: 4px;
  background-color: #fff;
}

.p-multiselect .p-multiselect-trigger {
  &:after {
    content: '';
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #444;
  }
}

.p-multiselect .p-multiselect-trigger .fa {
  display: none;
  /*we arent using FA*/
}

.p-multiselect .p-multiselect-label {
  padding: 0.375rem 2rem 0.375rem 0.75rem;
  font-weight: normal;
  margin-bottom: 0;
}

.p-multiselect-panel .p-multiselect-list {
  padding-left: 0;
}

.p-multiselect-panel .p-multiselect-item label {
  font-weight: 400;
  font-size: 1rem;
  padding-left: 1.75rem;
}

.p-multiselect-panel .p-multiselect-item.p-highlight {
  border-color: #35a2ac;
  background: #35a2ac;
  color: #ffffff;

  label {
    color: #fff;
  }
}

.p-multiselect-header {
  border: none;
  background: #f6f7f9;
  color: #1b1d1f;
  font-weight: normal;
}

.p-multiselect-header .p-inputtext {
  background-image: url(/assets/img/search-icon-green.svg);
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  background-color: #fff;

  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem 0.375rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #444;
  border: 1px solid #cecdcd;
}

.p-multiselect-header .p-chkbox {
  margin-top: 0.55rem;
}

.p-multiselect-header .p-multiselect-filter-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 83%;
  margin: 0 0 0 1.5rem;
}

/*-------------------------------------------------------PrimeNG AutoComplete--------------------------------------------------*/

.p-autocomplete {
  display: flex;

  .p-autocomplete-dropdown {
    right: 0;
    top: 0;
    -webkit-appearance: none;
    border: none;
    margin-right: 0;
    background-color: var(--primary);
    display: flex;
    height: calc(2.15rem + 2px);
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      background: url(/assets/img/right-arrow-white.svg) no-repeat center;
      width: 0.75rem;
      height: 0.75rem;
      display: inline-block;
      position: absolute;
      transform: rotate(90deg);
    }
  }

  &-input {
    padding-right: 2.2rem;
    @extend .form-control;
  }

  &-panel {
    width: 100%;
    background-color: #fff;
  }

  &-panel .p-autocomplete-list-item:hover {
    background-color: #eee;
  }
}

.ui-multiselect-items-wrapper {
  height: 20rem;
  padding-top: 3rem;

  li {
    &.p-highlight {
      font-size: 0.9rem;
    }
  }
}
