/* =============================================================================================
	SECTIONS (this is now replacing containers)
============================================================================================= */

.section {
  padding: 0;
  margin: 0;

  &--expandable {
    transition: all 0.25s ease-in-out;
  }
}

@media (min-width: 768px) {
  .section {
    padding: 1.5rem 0;
    margin: 0 1.5rem;
    flex: 1;
  }
}
