//Import Material
@use '@angular/material' as mat;
// @use '@angular/material/core/theming';

/* =============================================================================================
	VARIABLES
============================================================================================= */

:root {
  --error: #ed677b;
  --warning: #e8ad3e;
  --sidenav-width-minimized: 2rem;
  --sidenav-width: 16.5rem;
  --font-stack: 'Montserrat', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';

  --ramp-magenta: #b066d7;
  --ramp-dark-magenta: #8d52ac;
  --ramp-light-magenta: #c085df;
  --ramp-red: #f03b66;
  --ramp-red-highlight: 240, 59, 102, 0.1;
  --accent: var(--ramp-blue);
  --accent-color: var(--ramp-blue);
  --ramp-light-red: #f24f75;
  --ramp-dark-red: #d8355c;
  --ramp-green: #44a294;
  --primary: var(--ramp-dark-blue);
  --primary-color: var(--ramp-dark-blue);
  --ramp-dark-green: #377067;
  --ramp-light-gray-2: #f9f9f9;
  --ramp-alternate-color: #e6e8ea;
  --ramp-light-green-2: #eff8f7;
  --ramp-light-green: #9dd6cd;
  --light-green: var(--ramp-light-green-2);
  --ramp-green-deactive: #afd4d1;
  --ramp-orange: #c78500;
  --ramp-lighter-blue: #b8ddeb;
  --ramp-light-gray: #e6e8ea;
  --light-gray: var(--ramp-light-gray);
  --ramp-dark-gray: #535f69;
  --ramp-black: #333333;
  --ramp-gray: #e6e8ea;
  --ramp-light-blue: #bdc6d9;
  --ramp-dark-blue: #08214e;
  --ramp-blue: #426bf3;
  --ramp-blue-deactive: #6a7ea6;
  --ramp-white: #ffffff;
}

/* =============================================================================================
	PALETTES
============================================================================================= */

//Primary Palette
$my-primary: (
  50: #e5e8ef,
  100: #bdc6d9,
  200: #93a1bf,
  300: #6a7ea6,
  400: #4b6394,
  500: #294185,
  600: #22437d,
  700: #193a72,
  800: #123065,
  900: #08214e,
  A100: #76fffc,
  A200: #43fffb,
  A400: #10fffa,
  A700: #00f6f1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
//Accent Palette
$my-accent: (
  50: #fde7ed,
  100: #fbc4d1,
  200: #f89db3,
  300: #f57694,
  400: #f2587d,
  500: #f03b66,
  600: #ee355e,
  700: #ec2d53,
  800: #e92649,
  900: #e51938,
  A100: #ffffff,
  A200: #ffe4e7,
  A400: #ffb1bb,
  A700: #ff97a4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$my-dark-primary: (
  50: #f6f6f6,
  100: #e8e8e8,
  200: #d9d9d9,
  300: #cacaca,
  400: #bebebe,
  500: #b3b3b3,
  600: #acacac,
  700: #a3a3a3,
  800: #9a9a9a,
  900: #8b8b8b,
  A100: #ffffff,
  A200: #fdf0f0,
  A400: #ffbbbb,
  A700: #ffa1a1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

// Light Theme Variables (for dark theme variables see theme.scss)
$my-theme-primary: mat.m2-define-palette($my-primary);
$my-theme-accent: mat.m2-define-palette($my-accent);
$my-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

// scss variables
$border-color: #dbdbdb;
