/* =============================================================================================
	FORMS (TO DO: DEPRECATE BS FORMS IN FAVOUR OF MATERIAL)
============================================================================================= */

form {
  margin-bottom: 0;
}

label {
  display: inline-block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
}

.form-control {
  display: block;
  background-clip: padding-box;
  width: 100%;
  padding: 0.375rem 0.75rem 0.375rem 0;
  line-height: 1.5;
  background-color: #fff;

  font-size: 0.9rem;
  height: calc(2.15rem + 2px);
  border: 0;
  border-bottom: 1px solid $border-color;
  border-radius: 0;
  transition: border-color 0.25s ease, background-color 0.25s ease;
  color: #444;
  font-family: var(--font-stack);

  &:focus,
  &:active {
    border-bottom-color: #ff80bc;
    outline: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    color: #444;
  }
}

textarea.form-control {
  border: 1px solid $border-color;

  &:focus,
  &:active {
    border: 1px solid $border-color;
  }
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*-------------------------------------------------------Autofill and placeholders--------------------------------------------------*/

/*style chrome input autofill*/
input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset;
}

/*style placeholders*/
::-webkit-input-placeholder {
  color: #8d8d8d;
  font-style: italic;
}

::-moz-placeholder {
  color: #8d8d8d;
  font-style: italic;
}

:-ms-input-placeholder {
  color: #8d8d8d;
  font-style: italic;
}

:-moz-placeholder {
  color: #8d8d8d;
  font-style: italic;
}

/*style selection*/
::-moz-selection {
  background: #cce8ea;
}

::selection {
  background: #cce8ea;
}

::-moz-selection {
  background: #cce8ea;
}

/*-------------------------------------------------------Material Form Field--------------------------------------------------*/

.mat-mdc-form-field {
  flex: 1;
  width: 100%;
}

.mat-mdc-card .mat-mdc-form-field {
  flex: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field--no-padding > .mat-form-field-wrapper {
  margin-bottom: -1.25em;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checkmark {
  height: auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-layout {
  font-size: 0.875rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.mat-mdc-autocomplete-panel {
  min-width: 380px !important;
  max-width: none !important;
}

@media (min-width: 1200px) {
  .mat-mdc-select-panel {
    max-height: 30rem !important;
    max-width: none !important;
  }
}
