/* =============================================================================================
	LISTS
============================================================================================= */

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;

  &__item {
    @include grid(0.25rem);
    grid-template-columns: max-content 1fr;
    align-items: center;
    position: relative;
    padding: 0.35rem 1rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ebebeb;
    font-size: 0.875rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    min-width: 7rem;
  }
}
