/* =============================================================================================
	LINKS
============================================================================================= */

a {
  color: var(--accent);
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  transition: all 0.25s;

  &:hover,
  &:focus,
  &:active {
    color: var(--accent);
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }

  &:not([href]):not([tabindex]):hover,
  &:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
  }

  &:not([href]):not([tabindex]):focus {
    outline: 0;
  }
}

/* =============================================================================================
	BUTTONS (USE MATERIAL BUTTONS WHERE APPLICABLE)
============================================================================================= */

.mat-mdc-button:focus,
button:focus {
  box-shadow: none;
  outline: none;
}

.btn--next {
  &:after {
    content: '';
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
    height: 10px;
    background: url(/assets/img/right-arrow-white.svg) no-repeat;
    background-size: 7px auto;
    transition: all 0.5s ease;
  }

  &:hover:after {
    right: 0.75rem;
  }
}

/*more link with arrow after*/
.more {
  position: relative;
  display: inline-block;
  font-weight: 500;
  padding-right: 1.25rem;
  line-height: 1;
  color: var(--accent);

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
    background: url(/assets/img/right-arrow-pink.svg) no-repeat right center;
    background-size: 0.45rem auto;
    width: 0.45rem;
    height: 0.7rem;
    transition: all 0.25s ease;
  }

  &:hover:after,
  &:active:after {
    background-image: url(/assets/img/right-arrow-pink.svg);
    right: 0;
  }
}

.calendar-btn {
  padding: 0;
  width: 1.4rem !important;
  height: 1.4rem !important;
  background-color: #fff !important;
  appearance: none;
  box-shadow: none !important;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-wrapper {
    padding: 0 !important;
  }

  svg {
    width: 1rem;
    height: 1rem;
    stroke: var(--primary);
  }
}

/* =============================================================================================
	BTN GROUP
============================================================================================= */

.btn-group {
  @include grid(0.5rem);
  /* autoprefixer: ignore next*/
  grid-auto-flow: column;

  &--stacked {
    grid-auto-flow: row;
  }
}

/* =============================================================================================
	MATERIAL MINI FAB
============================================================================================= */

.mat-mdc-mini-fab {
  display: flex !important;
  justify-content: center;
  align-items: center;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-wrapper {
    line-height: 0 !important;
    padding: 0 !important;
  }
}
