/* =============================================================================================
    MIXINS
============================================================================================= */

/*A basic grid mixin*/
@mixin grid($grid-gap: 1rem) {
  display: grid !important;
  grid-gap: $grid-gap; //1rem default
}

/*Mixin used on keywords component for cards to respond independently from table width*/
@mixin keywords-responsive-card() {
  width: calc(100vw - 1.25rem);

  @media (min-width: 768px) {
    width: calc(100vw - 4.25rem);
  }

  @media (min-width: 992px) {
    width: calc(100vw - 4.5rem);
  }
}

@mixin scaled-loader($loader-scale) {
  @keyframes scaled-loader {
    0% {
      box-shadow: 0 -0.83em * $loader-scale 0 -0.4em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.42em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.44em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.46em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.477em * $loader-scale;
    }

    5%,
    95% {
      box-shadow: 0 -0.83em * $loader-scale 0 -0.4em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.42em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.44em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.46em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.477em * $loader-scale;
    }

    10%,
    59% {
      box-shadow: 0 -0.83em * $loader-scale 0 -0.4em * $loader-scale,
        -0.087em * $loader-scale -0.825em * $loader-scale 0 -0.42em * $loader-scale,
        -0.173em * $loader-scale -0.812em * $loader-scale 0 -0.44em * $loader-scale,
        -0.256em * $loader-scale -0.789em * $loader-scale 0 -0.46em * $loader-scale,
        -0.297em * $loader-scale -0.775em * $loader-scale 0 -0.477em * $loader-scale;
    }

    20% {
      box-shadow: 0 -0.83em * $loader-scale 0 -0.4em * $loader-scale,
        -0.338em * $loader-scale -0.758em * $loader-scale 0 -0.42em * $loader-scale,
        -0.555em * $loader-scale -0.617em * $loader-scale 0 -0.44em * $loader-scale,
        -0.671em * $loader-scale -0.488em * $loader-scale 0 -0.46em * $loader-scale,
        -0.749em * $loader-scale -0.34em * $loader-scale 0 -0.477em * $loader-scale;
    }

    38% {
      box-shadow: 0 -0.83em * $loader-scale 0 -0.4em * $loader-scale,
        -0.377em * $loader-scale -0.74em * $loader-scale 0 -0.42em * $loader-scale,
        -0.645em * $loader-scale -0.522em * $loader-scale 0 -0.44em * $loader-scale,
        -0.775em * $loader-scale -0.297em * $loader-scale 0 -0.46em * $loader-scale,
        -0.82em * $loader-scale -0.09em * $loader-scale 0 -0.477em * $loader-scale;
    }

    100% {
      box-shadow: 0 -0.83em * $loader-scale 0 -0.4em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.42em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.44em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.46em * $loader-scale,
        0 -0.83em * $loader-scale 0 -0.477em * $loader-scale;
    }
  }
}
