/* =============================================================================================
	HR (TO DO: DEPRECATE IN FAVOUR OF MATERIAL)
============================================================================================= */

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
