/* =============================================================================================
	CARDS
============================================================================================= */

.card {
  &--bg-white-60 {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &__icon {
    margin-right: 0.5rem;
    stroke: var(--primary);
    width: 1.5rem;
    height: 1.5rem;
  }
}

.mat-mdc-card {
  display: flex !important;
  flex-direction: column;

  &-content {
    display: flex !important;
    flex-direction: column;
  }

  &-title {
    margin-bottom: 0 !important;
  }

  &-header-text {
    margin: 0 !important;
  }

  .mat-divider {
    position: static !important;
    margin: 1.5rem 0;
  }
}
