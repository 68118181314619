/* =============================================================================================
	TABLES
============================================================================================= */

/*overriding prime-ng styles*/
.p-datatable {
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: auto !important;
    background-color: #fff;
  }

  .p-datatable-thead > tr > th,
  .p-datatable-tbody > tr > td,
  .p-datatable-tfoot > tr > td,
  .p-datatable-tfoot > tr > th {
    padding: 0.25rem;
  }

  thead th,
  tbody td,
  tfoot td,
  tfoot th {
    border: 1px solid #ebebeb !important;
  }

  .p-column-title {
    color: var(--primary);
  }

  thead td,
  thead th {
    border-bottom-width: 1px;
  }

  thead th,
  tfoot td {
    text-align: left;
  }

  thead tr {
    border-width: 0;
  }

  .p-datatable-thead > tr > th {
    color: var(--primary);
    font-size: 0.7rem;
    line-height: 1.3;
    border-bottom: 1px solid #ebebeb;
    font-weight: 500;
    vertical-align: bottom;
    white-space: nowrap;

    /*sticky table headers*/
    position: sticky;
    top: -1px;
    z-index: 6;
    background-color: #fff;
  }

  thead th.p-menuitem-link-active {
    background-color: #cce9e9 !important;
    border-color: #cce9e9;
    color: var(--primary);
  }

  /*tbody tr*/
  .p-component-content {
    background-color: #ffffff;
    border: none;
  }

  /*tr zebra striping*/
  .p-datatable-tbody tr:nth-child(odd) {
    background-color: #f6f6f7;
  }

  /*tr hover*/
  .p-datatable-tbody tr:hover {
    background-color: #cce9e9 !important;
    cursor: pointer;
  }

  /*td*/
  .p-datatable-tbody tr td {
    font-size: 0.7rem;
    line-height: 1.4;
    vertical-align: middle;

    a {
      word-break: break-word;
      cursor: pointer;
    }
  }

  /*table footer*/
  tfoot td,
  tfoot th {
    background-color: var(--primary);
    color: #fff;
    font-size: 0.85rem;
    font-weight: 500;
  }

  .selected {
    background-color: #cce9e9 !important;
  }

  p {
    font-size: 0.8rem;
  }

  .table__subheading {
    margin-bottom: 0.4rem;
    color: var(--primary);
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0.015rem;
  }

  /*for dynamic inputs in a table*/
  input[type='text']:not(.filter-text),
  input[type='number'],
  select {
    @extend .form-control;
    height: 1.6rem;
    border-top: 1px solid #dbdbdb !important;
    border-right: 1px solid #dbdbdb !important;
    border-left: 1px solid #dbdbdb !important;
    font-size: 0.75rem !important;
  }

  input[type='text'].date-input {
    border: 0 !important;
    box-shadow: none !important;
    height: 1rem !important;
    min-width: 5rem !important;
    outline: 0 !important;
    top: -1px;
  }

  /*editable table cell inputs*/
  .p-datatable-tbody > tr > td.p-editing-cell select,
  .p-datatable-tbody > tr > td.p-editing-cell input {
    padding: 0.35rem !important;
    height: 100%;
    border: none !important;
    box-shadow: none !important;
    margin: 0 !important;
    text-align: inherit;
  }

  /*text inputs*/
  .p-column-filter,
  .p-dropdown {
    display: block;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem;
    line-height: 1.5;
    color: #444;
    border: 1px solid #cecdcd;
    border-radius: 4px;
    margin-top: 0.25rem;
  }

  /*sortable column*/
  .p-sortable-column {
    position: relative;

    &.p-state-highlight {
      background-color: #cce9e9;
      color: var(--primary);
    }

    &-icon {
      &.fa-sort {
        &:before,
        &:after {
          border: 4px solid transparent;
          content: '';
          display: block;
          height: 0;
          right: 0.85rem;
          top: 1rem;
          position: absolute;
          width: 0;
        }

        &:before {
          border-bottom-color: #bfbfbf;
          margin-top: -9px;
        }

        &:after {
          border-top-color: #bfbfbf;
          margin-top: 1px;
        }
      }

      &.fa-sort-asc {
        &:before {
          border-bottom-color: var(--accent);
        }

        &:after {
          content: none;
        }
      }

      &.fa-sort-desc {
        &:before {
          content: none;
        }

        &:after {
          border-top-color: var(--accent);
        }
      }
    }
  }
}

.p-datatable-caption {
  padding: 0;
}

.p-datatable .p-selection-column .p-chkbox,
.p-datatable .p-selection-column .p-radiobutton {
  margin: 0;
  display: block;
}

.p-datatable .p-selection-column .p-chkbox-box,
.p-datatable .p-selection-column .p-radiobutton-box {
  display: block;
  box-sizing: border-box;
  margin: 0;
}

.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable-resizable .p-datatable-tbody > tr > td {
  overflow: visible;
}

/*table aligning*/
.align-left.p-sortable-column,
td.align-left {
  text-align: left;
}

.align-center.p-sortable-column,
td.align-center {
  text-align: center;
}

@media (max-width: 992px) {
  /*responsive table on mobile*/
  .p-datatable-responsive {
    thead th,
    tfoot td {
      display: none !important;
    }

    .p-datatable-tbody td {
      text-align: left;
      display: block;
      border: 0 none;
      width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      float: left;
      clear: left;
    }

    .p-datatable-tbody.p-component-content {
      border: 0 none;
    }

    .p-datatable-tbody.p-component-content :active:focus {
      background-color: #f8fdea !important;
    }

    .p-datatable-tbody tr.p-component-content {
      border-left: 0 none;
      border-right: 0 none;
    }

    .p-datatable-tbody td .p-column-title {
      padding: 0.4em;
      min-width: 42%;
      display: inline-block;
      margin: -0.4em 1em -0.4em -0.4em;
      font-weight: bold;
    }
  }

  .p-datatable .p-datatable-tbody tr td:active:focus {
    background-color: #f8fdea !important;
  }
}

@media (min-width: 992px) {
  .p-datatable {
    .p-datatable-tbody tr td {
      word-wrap: break-word;
    }

    .table__subheading {
      word-break: break-word;
    }

    .checkbox-col {
      width: 3.3125rem;
    }

    .status-col {
      width: 4.75rem;
    }

    .cost-col {
      width: 7rem;
    }
  }
}

@media (min-width: 1200px) {
  .p-datatable {
    .p-datatable-data > tr > td,
    .p-datatable-tbody tr td {
      word-break: initial;
    }

    .p-datatable-thead > tr > th {
      font-size: 0.75rem;
    }

    .p-datatable-tbody tr td {
      font-size: 0.75rem;
    }

    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td,
    .p-datatable-tfoot > tr > td,
    .p-datatable-tfoot > tr > th {
      padding: 0.35rem;
    }
  }

  /*q-grid wrapper*/
  app-q-grid {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    max-width: 100%;
    overflow: auto;
    flex: 1;
  }
}

/* =============================================================================================
  Table container / Responsive Table (wrap around tables)
============================================================================================= */

/*scrolling table*/
.table-container {
  display: block;
  width: 100%;
  min-height: 10rem;
  max-height: 100%;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* =============================================================================================
  Table Actions
============================================================================================= */

.table-actions {
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    padding: 0 0.1rem;
  }

  > a,
  > div {
    margin-left: 0.2rem;
  }

  &__last-updated {
    display: inline-block;
    background-color: #117a79;
    color: #fff !important;
    padding: 0 0.1rem;
    border-radius: 2px;
    width: 1.4rem;
    text-align: center;
    font-size: 0.7rem;
    line-height: 1.5;
    white-space: nowrap;
  }

  &__btn {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0;

    &[disabled] {
      .table-actions__icon {
        stroke: gray;
        cursor: not-allowed;
      }
    }
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    display: block;
    margin: 0 auto;
    stroke: var(--primary);
    transition: all 0.25s;
    cursor: pointer;

    &:hover {
      stroke: var(--accent);
    }

    &--sps-icon {
      stroke: none;
      fill: var(--primary);

      &:hover {
        stroke: none;
        fill: var(--accent);
      }
    }

    &--opened {
      background: url(/assets/img/minus-icon-teal.svg) no-repeat center;
    }

    &--closed {
      background: url(/assets/img/plus-icon-teal.svg) no-repeat center;
    }

    &--delete-btn {
      stroke: #ca0000;
      cursor: pointer;

      &:hover {
        stroke: #000;
      }
    }
  }

  .q-tooltip + .q-tooltip {
    margin-left: 0.25rem;
  }
}

/* =============================================================================================
  Sticky Table Column Headers (Note: Use CSS sticky instead of this if possible)
============================================================================================= */

@media (min-width: 2100px) {
  .table-sticky-header-bar,
  .table-sticky-header {
    position: fixed !important;
    top: 4.4rem;
  }

  .table-sticky-header {
    z-index: 1;
    font-size: 0.75rem;
    padding: 0.5rem 0 0.5rem 0;
    width: 100%;
  }

  .p-datatable .p-column-title.table-sticky-header {
    color: #fff;
  }

  .table-sticky-header-bar {
    z-index: 0;
    margin-left: -1.5rem;
    width: 100%;
    height: 2rem;
    background-color: var(--primary);
  }
}

/* =============================================================================================
  Options Bar (sits on top of tables)
============================================================================================= */

.options-bar {
  @include grid;
  justify-content: flex-end;
  /* autoprefixer: ignore next*/
  grid-auto-flow: column;
  grid-template-columns: minmax(11rem, 25%) auto;
  background-color: var(--light-green);
  padding: 0.5rem 0.8rem;
  min-height: 3.25rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: minmax(15rem, 25%) auto;
  }

  &__page-size {
    display: flex;
    align-items: center;
    /* autoprefixer: ignore next*/
    grid-column-end: -1;

    &-label {
      color: rgba(0, 0, 0, 0.54);
      font-size: 12px;
      white-space: nowrap;
      margin-right: 0.5rem;
    }

    &-field {
      font-size: 12px;
      width: 3.5rem;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper {
      width: 3.5rem;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
      border-top: 0;
    }
  }

  &__filter {
    position: relative;
    @include grid;

    &-icon {
      position: absolute;
      left: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      width: 1.4rem;
      height: 1.4rem;
      stroke: var(--primary);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
      .mat-form-field-label,
    .mat-form-field-appearance-legacy.mat-form-field-can-float
      .mat-input-server:focus
      + .mat-form-field-label-wrapper
      .mat-form-field-label {
      top: 1.5em;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
      padding-left: 3rem;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-label {
      font-style: italic;
      margin-left: 3rem;
      top: 0.9em;
    }
  }

  &__filter-field {
    position: relative;
    background-color: #fff;
  }
}

.mat-focused {
  + .options-bar__filter-icon {
    stroke: var(--accent);
  }
}
