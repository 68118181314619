/* =============================================================================================
	MAT TOOLTIP
============================================================================================= */

.mat-mdc-tooltip {
  white-space: pre-line;
  max-width: 400px !important;
}

.q-grid-tooltip {
  position: relative;

  &__btn {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    color: var(--primary);
    border: 2px solid var(--primary);
    line-height: 1.2;
    background-color: #fff;
    -webkit-appearance: none;

    &--error,
    &--warning {
      width: 1rem;
      height: 1rem;
      margin-left: 0.25rem;
      background-color: transparent;
      border: none;
      border-radius: 0;
      padding: 0;
      color: transparent;
      margin-top: -0.5rem;
    }

    &--error .q-grid-tooltip__icon,
    &--warning .q-grid-tooltip__icon {
      width: 1rem;
    }

    &--error .q-grid-tooltip__icon {
      stroke: var(--error);
    }

    &--warning .q-grid-tooltip__icon {
      stroke: var(--warning);
      fill: var(--warning);
    }
  }

  &__icon {
    pointer-events: none;
  }

  &__content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 999;
    background: rgba(68, 68, 68, 0.93);
    text-align: left;
    color: #fff;
    padding: 0.75rem 1rem;
    line-height: 1.4;
    font-size: 0.75rem;
    border-radius: 5px;
    pointer-events: none;
    min-width: 5rem;
    transition: all 0.25s ease-in-out;

    h3 {
      color: #fff;
      text-align: center;
      margin-bottom: 0.75rem;
    }

    ul {
      padding-left: 1.5rem;
    }

    p,
    li {
      color: rgba(255, 255, 255, 0.9);
      font-size: 0.75rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &:hover,
  &:focus {
    .q-grid-tooltip__content {
      visibility: visible;
      opacity: 1;
    }
  }

  &--error {
    .q-grid-tooltip__content {
      background-color: var(--error);
    }
  }

  &--warning {
    .q-grid-tooltip__content {
      background-color: var(--warning);
    }
  }
}
