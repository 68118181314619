/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 0 !important;
  height: 2px !important;
  background-color: var(--ramp-blue) !important;
}

.mat-form-field-underline {
  bottom: 0 !important;
  height: 2px !important;
  background-color: var(--ramp-blue) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .page-dropdown .mat-form-field-appearance-legacy .mat-form-field-underline {
//   width: 75% !important;
//   margin-left: 0.5rem !important;
// }

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper {
  background-color: var(--ramp-light-gray-2);
}

.mat-mdc-form-field {
  background-color: var(--ramp-light-gray-2);
  margin-bottom: 1rem;
  padding: 0 !important;
  min-height: 75px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .page-dropdown .mat-form-field-wrapper {
//   background-color: var(--ramp-light-green-2);
// }

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field--no-padding > .mat-form-field-wrapper {
  padding-bottom: unset !important;
  height: 4.075rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-flex {
  padding: 1rem;
  display: inline-flex;
  align-items: baseline;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--ramp-light-gray-2);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.page-dropdown .mat-form-field-wrapper {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    background-color: var(--ramp-light-blue-2);
    display: flex;
    align-items: center;
  }
  height: 3.1rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
lib-searchable-select-control .mat-form-field-flex {
  padding: 1rem 1rem 0 1rem;
}

formly-field {
  height: 3rem;
  margin-bottom: 1rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-infix {
  border-top: none !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-form-field-appearance-legacy .mat-mdc-form-field-hint {
  color: rgba(0, 0, 0, 0.54);
  padding: 0 1rem 1rem;
  line-height: 0.8rem;
}

.mat-mdc-form-field-hint {
  padding-top: 1.2rem !important;
  padding-left: 1rem;
  line-height: 1rem;
  font-size: 0.8rem;
  color: var(--ramp-dark-gray) !important;
}

.mat-mdc-form-field-error {
  color: var(--ramp-red) !important;
  padding: 0 1rem 1rem;
  line-height: 0.9rem;
  margin-top: -0.7rem !important;
  font-size: 0.8rem;
}

:root {
  --sidenav-width: 19rem !important;
}

mat-sidenav {
  background-color: transparent !important;
  overflow: hidden !important;
  border-right: none !important;
}

main.main {
  padding-top: 0 !important;
}

formly-field {
  display: contents;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .mat-form-field-label {
//   color: var(--ramp-dark-green) !important;
//   padding-top: 0.1rem;
// }

// input field validate error, red underline thickness.
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .mat-form-field-ripple {
//   height: 2px!important;
// }

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
  background: rgba(0, 0, 0, 0) !important;
}

.mat-expansion-panel-header-description {
  flex-grow: 0 !important;
  padding-top: 0.3rem;
  min-width: fit-content;
}

/* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/
mat-tooltip-component {
  transform: translateY(-8px);

  .mat-mdc-tooltip {
    font-weight: 400;
    color: white !important;
    font-size: 0.85rem !important;
    padding: 0.5rem !important;
    font-family: 'Montserrat';
  }

  .mdc-tooltip__surface {
    background-color: var(--ramp-black) !important;
  }
}

.mdc-checkbox__checkmark {
  top: auto !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--ramp-magenta) !important;
}

.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.checkbox-config {
  border-bottom-color: var(--ramp-blue) !important;
}

.cdk-overlay-pane {
  min-width: 6rem !important;
}

.domain-manager-buttons .mat-mdc-select-arrow-wrapper {
  display: none !important;
}

// Dock style adjustments
.bottom-dock__container {
  box-shadow: 0 0 8px 0px #00000022 !important;
  padding: 15px !important;
  background-color: var(--ramp-white) !important;
  border-radius: 15px !important;
  height: 600px !important;
}

.dock__btn-group {
  right: 3rem !important;
  top: 1.25rem !important;

  .dock__max-btn svg {
    transform: unset;
  }

  .dock__min-btn svg {
    transform: rotate(180deg);
  }

  .dock__close-btn svg,
  .dock__max-btn svg,
  .dock__min-btn svg {
    width: 1rem;
    height: 1rem;
    stroke: #091b29;
    stroke-width: 1px;
    overflow: visible;
  }

  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    box-shadow: unset;
  }

  .mat-mdc-fab:hover,
  .mat-mdc-fab:focus,
  .mat-mdc-mini-fab:hover,
  .mat-mdc-mini-fab:focus {
    box-shadow: unset;
  }
  .mat-mdc-fab:active,
  .mat-mdc-fab:focus:active,
  .mat-mdc-mini-fab:active,
  .mat-mdc-mini-fab:focus:active {
    box-shadow: unset;
  }
}

lib-dock {
  .dock {
    grid-gap: unset;
  }
  .section {
    padding-top: 1rem;
  }
  .dock__no-menu {
    border-top-color: var(--ramp-light-blue) !important;
  }
}

// Checkbox
app-checkbox-type {
  .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background: unset !important;
  }

  .mat-mdc-checkbox-ripple,
  .mdc-checkbox__ripple {
    display: none !important;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate='true']:enabled
    ~ .mdc-checkbox__background {
    border-color: var(--ramp-blue) !important;
    background-color: var(--ramp-blue) !important;
  }
}

// tab header style
app-tabs-type {
  .mat-mdc-tab-header {
    padding-bottom: 2rem;
    display: grid;
  }

  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: unset;
  }

  .mdc-tab {
    padding-right: 1rem;
    padding-left: 1rem;
    width: fit-content;
  }
}

.mat-snackbar--working simple-snack-bar::before {
  margin-top: -1.7rem;
  margin-right: 1rem;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
