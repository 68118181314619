/* =============================================================================================
	IMAGES
============================================================================================= */

img {
  vertical-align: middle;
  border-style: none;
}

.heading-icon {
  width: 0.9em;
  height: 0.9em;
  vertical-align: -5%;
  margin-right: 0.75rem;
}

.status-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
}

.placeholder-img {
  max-height: 10rem;
  display: block;
  margin: 0 auto;
}

svg {
  overflow: hidden;
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
}

.highcharts-root {
  width: 100%;
  height: auto;
  stroke: none;
}

@media (min-width: 1600px) {
  .placeholder-img {
    max-height: 15rem;
  }
}
